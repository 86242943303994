// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as H5 from "../../../../../styleguide/components/Heading/H5.res.js";
import * as IconDownload from "../../../../../styleguide/icons/IconDownload.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_ProviderBrochure from "../../../../../routes/common/Routes_ProviderBrochure.res.js";
import * as ProviderBrochuresIndexBrochureScss from "./ProviderBrochuresIndexBrochure.scss";

var css = ProviderBrochuresIndexBrochureScss;

function ProviderBrochuresIndexBrochure(props) {
  var show = props.show;
  return JsxRuntime.jsx(A.make, {
              href: Routes_ProviderBrochure.show(show.providerBrochure.slug),
              className: css.linkContainer,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("img", {
                                  className: css.image,
                                  src: show.providerBrochure.image
                                }),
                            className: css.imageContainer
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: show.provider.name,
                                    className: css.provider
                                  }),
                              JsxRuntime.jsx(H5.make, {
                                    className: css.title,
                                    children: show.providerBrochure.title
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: show.providerBrochure.subtitle,
                                    className: css.subtitle
                                  })
                            ],
                            className: css.titleContainer
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(IconDownload.make, {
                                  size: "SM",
                                  color: "DarkGray",
                                  className: css.download
                                }),
                            className: css.downloadContainer
                          })
                    ],
                    className: css.providerBrochure
                  })
            });
}

var make = ProviderBrochuresIndexBrochure;

export {
  css ,
  make ,
}
/* css Not a pure module */
